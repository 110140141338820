<template>
  <v-app-bar app color="white" class="elevation-pl-3">
    <v-container class="d-flex">
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo-lc-internet.svg"
          transition="scale-transition"
          width="170"
        />
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center" v-if="$route.name === 'Home'">
        <v-btn icon @click="logout">
          <icon-logout class="icon--logout"></icon-logout>
        </v-btn>
      </div>

      <router-link
        :to="{name:'Home'}"
        class="d-flex align-center"
        v-if="$route.name !== 'Home'"
      >
        <icon-menu class="icon--menu"></icon-menu>
      </router-link>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar",
  components: {
    IconMenu: () => import("./icons/IconMenu"),
    IconLogout: () => import("./icons/IconLogout"),
  },
  methods: {
    logout() {
      localStorage.removeItem("LCInternet");
      this.$router.push({name: 'Login'});
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  border: 1px solid #ffff;
}

.icon--menu,
.icon--logout {
  max-width: 1.8rem;
  transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.icon--menu:hover,
.icon--logout:hover {
  transform: scale(1.2);
  cursor: pointer;
}
</style>